import { createAPIClient } from "@shopware/api-client";
import type { operations } from "#shopware";
import axios from "axios";
import Cookies from "js-cookie";

const runtimeConfig = useRuntimeConfig();

const shopwareEndpoint =
  runtimeConfig.public?.shopware?.endpoint ??
  runtimeConfig.public?.shopware?.shopwareEndpoint;
const shopwareAccessToken =
  runtimeConfig.public?.shopware?.accessToken ??
  runtimeConfig.public?.shopware?.shopwareAccessToken;

const apiClient = createAPIClient<operations>({
  accessToken: shopwareAccessToken,
  baseURL: shopwareEndpoint,
});

export default apiClient;

export async function getProductList(categoryId: any, body: any) {
  try {
    const url = shopwareEndpoint + `product-listing/${categoryId}`;

    const response = await axios.post(url, body, {
      headers: {
        'Sw-Access-Key': shopwareAccessToken,
        'Sw-Context-Token': Cookies.get('sw-context-token'),
        'Content-Type': 'application/json',
        'sw-include-seo-urls': 'true' // Include any necessary headers
      }
    });

    if (response && response.data) {
      return response.data;
    } else {
      throw new Error('Response or response data is undefined.');
    }
  } catch (error) {
    console.log('error:', error);
    return error;
  }
}

export async function getProductsById(body: any) {
  try {
    const url = shopwareEndpoint + `product`;

    const response = await axios.post(url, body, {
      headers: {
        'Sw-Access-Key': shopwareAccessToken,
        'Sw-Context-Token': Cookies.get('sw-context-token'),
        'Content-Type': 'application/json',
        'sw-include-seo-urls': 'true' // Include any necessary headers
      }
    });

    if (response && response.data) {
      return response.data;
    } else {
      throw new Error('Response or response data is undefined.');
    }
  } catch (error) {
    console.log('error:', error);
    return error;
  }
}
